<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial;overflow:initial  ">
            <el-row>
                <el-col :lg="24">
                    <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                        <el-row>
                            <el-col :lg="24" style="line-height: 27px;">
                                <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：打印-开单通知书</strong>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <el-row style="margin-bottom: 20px">
                <el-button type="primary" size="mini" @click="lodopPrint" style="margin-bottom: 2px;margin-top: 2px">
                    针式插件打印
                </el-button>
                <el-button type="primary" size="mini" @click="printTime(formData.bill_id_type,formData.bill_id_kdtzs)"
                           v-print="'#printDiv'" style="margin-bottom: 2px;margin-top: 2px">普通打印
                </el-button>
                <el-button type="primary" round :disabled="formData.is_cancel"
                           v-if="$_getActionKeyListBoolean(['/transferSlip/buildBillByTransferSlipService']) && this.isBlankVue(formData.bill_id_kd)"
                           @click="$_createBillByTransferSlip" size="mini">生成《销售开单》
                </el-button>
                <el-button type="primary" round :disabled="formData.is_cancel" v-if="!this.isBlankVue(formData.bill_id_kd)"
                           @click="$_createBillByTransferSlip" size="mini">查看《销售开单》
                </el-button>

                <el-button style=" margin-top: 12px;" type="primary" round :disabled="formData.is_cancel"
                           v-if="$_getActionKeyListBoolean(['/shippingAdvice/buildBillByShippingAdviceService']) && this.isBlankVue(formData.bill_id_fh)"
                           @click="$_createBillByPrintShippingAdvice" size="mini">生成《发货单》
                </el-button>
                <el-button style=" margin-top: 12px;" type="primary" round :disabled="formData.is_cancel"
                           v-if="!this.isBlankVue(formData.bill_id_fh)"
                           @click="$_createBillByPrintShippingAdvice" size="mini">查看《发货单》
                </el-button>
                <el-button type="primary" size="mini" @click="$router.back()"
                           style="margin-bottom: 2px;margin-top: 2px">返回
                </el-button>
            </el-row>
            <div>
                <div id="printDiv">
                    <div style="width: 100%;margin-right: 50px;float: left" v-for="index of pageSize" :key="index">
                        <div id="myMain" style="width: 100%;margin-bottom: 100px" ref="myMain">
                            <div :id="'tableDataMain'+index">
                                <div class="topHtml">
                                    <div class="my-list-row">
                                        <div class="my-list-col" style="width: 42mm;">
                                            <strong style=" font-size: 8mm ">开单通知书</strong>
                                            <img border="0" src="@/assets/test.png" v-if="$store.getters.getRunTimeTest"
                                                 style="z-index: -1; position: absolute; left:100px; top:200px;"/>
                                            <img border="0" src="@/assets/zf.png" v-if="formData.is_cancel"
                                                 style="z-index: -1; position: absolute; left:200px; top:150px;"/>
                                        </div>
                                        <div class="my-list-col" style="width: 45mm;  ">
                                            <a style=" margin-left: 1mm;font-size: 3.5mm;">{{formData.bill_id_kdtzs}}</a>
                                        </div>
                                        <div class="my-list-col" style="width: 45mm;">
                                            <a>日期:{{formatDate(formData.bill_date_kdtzs ,'yyyy年MM月dd日')}}</a>
                                        </div>
                                    </div>
                                    <div class="my-list-row" style="margin-top: 0.1mm">
                                        <div class="my-list-col" style="width: 130mm;font-size: 6mm;">
                                            <strong>客户:{{formData.customer}}</strong>
                                        </div>
                                    </div>
                                    <div class="my-list-row">
                                        <div class="my-list-col" style="width: 20mm;font-size: 6mm"><strong>备注:</strong>
                                        </div>
                                        <div class="my-list-col" style="width: 110mm;font-size: 6mm; "><strong>
                                            {{formData.remark_warehouse_bill?formData.remark_warehouse_bill +'。':'&nbsp;'}}</strong>
                                        </div>
                                    </div>
                                </div>
                                <table border="1" cellspacing="0" cellpadding="0"
                                       style="table-layer:fixed;text-align: center;width: 130mm">
                                    <tr>
                                        <th style="width: 5mm;">序号</th>
                                        <th style="width: 12mm;">仓库</th>
                                        <th style="width: 38mm;">规格</th>
                                        <th style="width: 5mm;">单位</th>
                                        <th style="width: 10mm;">支数</th>
                                        <th style="width: 10mm;">单价</th>
                                        <!-- <th style="width: 10mm;">实厚</th>-->
                                        <th style="width: 39mm;">备注</th>
                                        <th style="width: 10mm;" v-if="tableRow[index-1]"></th>
                                    </tr>
                                    <tr v-for="item in table[index-1]" :key="item.order_id">
                                        <td>{{$_getDataByBlank(item.order_id)}}</td>
                                        <td>{{$_getDataByBlank(item.warehouse_out )}}</td>
                                        <td>{{$_getDataByBlank(item.specification)}}</td>
                                        <td>{{$_getDataByBlank(item.unit)}}</td>
                                        <td>{{$_getDataByBlank(item.single_count_str)}}</td>
                                        <td>{{$_getDataByBlank(item.price)}}</td>
                                        <!--<td>{{$_getDataByBlank(item.actual_thickness)}}</td>-->
                                        <td style="word-break: break-all">
                                            {{item.category !='方管' && item.category != '矩管' ? item.category : ''}}
                                            {{$_getDataByBlank(item.remark_stock)}}
                                            {{item.full_thick !='' &&
                                            item.full_thick != null ? (!$_getDataByBlank(item.remark_stock) ? '':',' ) + $_getDataByBlank(item.full_thick) : ''}}
                                            {{item.theory_weight !='' &&
                                            item.theory_weight != null ? (!$_getDataByBlank(item.remark_stock) && !$_getDataByBlank(item.full_thick) ? '':',' ) + $_getDataByBlank(item.theory_weight) : ''}}
                                        </td>
                                        <td style="font-size: 3mm" v-if="tableRow[index-1]">{{item.single_price_practical != item.price ?
                                            $_getDataByBlank(item.single_price_practical) : ''}}
                                        </td>
                                    </tr>
                                </table>
                                <div class="bottomHtml">
                                    <div class="my-list-row">
                                        <!--checkList-->

                                        <div class="my-list-col" style="width: 25mm;">
                                            调拨单<span style="font-family:Wingdings;font-size:5mm;">{{checkList.indexOf('调拨单') == -1 ? '&#168;' : '&#254;'}}</span>
                                        </div>
                                        <div class="my-list-col" style="width: 25mm;">
                                            欠款单<span style="font-family:Wingdings;font-size:5mm;">{{checkList.indexOf('欠款单') == -1 ? '&#168;' : '&#254;'}}</span>
                                        </div>
                                        <div class="my-list-col" style="width: 15mm;">
                                            A<span style="font-family:Wingdings;font-size:5mm;">{{checkList.indexOf('A') == -1 ? '&#168;' : '&#254;'}}</span>
                                        </div>
                                        <div class="my-list-col" style="width: 15mm;">
                                            B<span style="font-family:Wingdings;font-size:5mm;">{{checkList.indexOf('B') == -1 ? '&#168;' : '&#254;'}}</span>
                                        </div>
                                        <div class="my-list-col" style="width: 38mm;"><a>赊销承诺结算时间:</a></div>
                                        <div class="my-list-col"
                                             style="width: 8mm; border-bottom: 0.5mm solid #000000; text-align: center;">
                                            <a> {{formData.settlement_days?formData.settlement_days:'0'}}</a></div>
                                        <div class="my-list-col" style="width: 2mm;"><a>天</a></div>
                                    </div>
                                    <div class="my-list-row">
                                        <!--clearingFormList-->
                                        <div class="my-list-col" style="width: 20mm;">
                                            支票<span style="font-family:Wingdings;font-size:5mm;">{{clearingFormList.indexOf('支票') == -1 ? '&#168;' : '&#254;'}}</span>
                                        </div>
                                        <div class="my-list-col" style="width: 20mm;">
                                            汇款<span style="font-family:Wingdings;font-size:5mm;">{{clearingFormList.indexOf('汇款') == -1 ? '&#168;' : '&#254;'}}</span>
                                        </div>
                                        <div class="my-list-col" style="width: 20mm;">
                                            现金<span style="font-family:Wingdings;font-size:5mm;">{{clearingFormList.indexOf('现金') == -1 ? '&#168;' : '&#254;'}}</span>
                                        </div>
                                        <div class="my-list-col" style="width: 30mm;">
                                            刷卡转账<span style="font-family:Wingdings;font-size:5mm;">{{clearingFormList.indexOf('刷卡转账') == -1 ? '&#168;' : '&#254;'}}</span>
                                        </div>
                                        <div class="my-list-col" style="width: 20mm;">
                                            微信<span style="font-family:Wingdings;font-size:5mm;">{{clearingFormList.indexOf('微信') == -1 ? '&#168;' : '&#254;'}}</span>
                                        </div>
                                        <div class="my-list-col" style="width: 20mm;">
                                            承兑<span style="font-family:Wingdings;font-size:5mm;">{{clearingFormList.indexOf('承兑') == -1 ? '&#168;' : '&#254;'}}</span>
                                        </div>
                                    </div>
                                    <div class="my-list-row">
                                        <div class="my-list-col" style="width: 25mm;"><a>司机、车号:</a></div>
                                        <div class="my-list-col"
                                             style="width: 100mm; border-bottom: 0.5mm solid #000000; "><a>
                                            {{formData.car_code?formData.car_code :'&nbsp;'}}</a></div>
                                    </div>
                                    <div class="my-list-row">
                                        <div class="my-list-col" style="width: 10mm;"><a>税率:</a></div>
                                        <div class="my-list-col"
                                             style="width: 40mm; border-bottom: 0.5mm solid #000000; "><a>
                                            {{formData.tax_tate_warehouse_bill ? formData.tax_tate_warehouse_bill :'&nbsp;'}}</a>
                                        </div>
                                        <div class="my-list-col" style="width: 15mm;"><a>业务员:</a></div>
                                        <div class="my-list-col"
                                             style="width: 60mm; border-bottom: 0.5mm solid #000000; ">
                                            <a> {{formData.sales_man ?formData.sales_man :'&nbsp;'}}{{formData.total_theoretical_weight?$XEUtils.round(Number(formData.total_theoretical_weight),3):'&nbsp;'}}</a>
                                            <a style="margin-left: 5mm">第{{index}}页，共{{pageSize}}页</a>
                                        </div>
                                    </div>
                                    <!--<div class="my-list-col" style="width: 125mm;text-align: center;">
                                        <a style="float: left">{{formData.total_theoretical_weight?$XEUtils.round(Number(formData.total_theoretical_weight),3):'&nbsp;'}}</a>
                                        <a style="float: right">第{{index}}页，共{{pageSize}}页。</a>
                                    </div>-->
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </el-main>
    </div>
</template>

<script>


    export default {

        data() {
            return {
                table: [],
                tableRow: [],
                tableDataMain: [],
                checkList: [],
                pageSize: 1,
                dataSize: 0,
                clearingFormList: ['微信'],
                formData: {
                    clearing_form: '欠款',
                    other_expenses : '费用',
                    other_expenses_price : '',
                    customer_phone: '',
                    car_code : '',
                    bill_date: '',
                    bill_id_type: '',
                    bill_date_kd : '',
                    bill_date_dd : '',
                    bill_date_kdtzs : new Date(),
                    contract_id : '',
                    delivery_date: '',
                    kick_back : '',
                    total_theoretical_weight: '',
                    bill_id_dd: '',
                    bill_id_kd : '',
                    bill_id_kdtzs : '',
                    bill_id: '',
                    settlement_days: '',
                    customer: '',
                    sales_man : '',
                    billing_company_name: '',
                    remark_warehouse_bill: '',
                    registrar: '',
                    total_amount: '',
                    practical_total_price : '',
                    total_number: '',
                    is_ticket: '',
                    last_name_warehouse_bill: '',
                    last_date_warehouse_bill: '',
                    bill_type: '开单通知书',
                    creat_date_warehouse_bill : '',
                    handled_by: '',
                    consignee: '',
                    tax_tate_warehouse_bill : '',
                    supplier: '',
                    additional_charges : '',
                    amount_of_product : '',
                    cost_of_the_unit : '',
                    total_weighing_heavy : '',
                    pounds_fees : '',//磅费
                    wind_cut_fees : '',//风割费
                    loading_car_fees : '',//装车费
                    transfer_fees : '',//中转费
                    freight_fees : '',//运费
                    taxes_fees : '',//税金
                    is_cancel: false,
               },
                input: '',
                rotateTimes: 1
           }

       },
        methods: {
            lodop() {
                //打印单
                const LODOP = this.$getLodop()
                const strStyle = "<style>.my-list-row {display: inline-block; width: 100%;} .my-list-col {float: left; width: 33.33%; line-height: 28px;} .my-top,.my-bottom {font-size: 12px;} .my-top {margin-bottom: 5px;} .my-bottom {margin-top: 30px; text-align: right;}</style>"
                LODOP.SET_PRINT_PAGESIZE(1, 2000, 1400, "");//这里3表示纵向打印且纸高“按内容的高度”；1385表示纸宽138.5mm；45表示页底空白4.5mm
                for (let i = 1; i < this.pageSize + 1; i++) {
                    if (i > 1) {
                        LODOP.NewPageA();
                   }
                    LODOP.ADD_PRINT_HTM(15, 5, "100%", "100%","<!DOCTYPE html>"+ strStyle + "<body>" + document.getElementById("tableDataMain" + i).innerHTML + "</body>");
                    LODOP.SET_PRINT_STYLEA(0, "AngleOfPageInside", 90);
                    LODOP.SET_PRINT_PAGESIZE(1, 2000, 1400, "");//这里3表示纵向打印且纸高“按内容的高度”；1385表示纸宽138.5mm；45表示页底空白4.5mm
               }
                return LODOP;
           },
            lodopPrint() {
                const LODOP = this.lodop()
                LODOP.PREVIEW();
                this.printTime(this.formData.bill_id_type, this.formData.bill_id_kdtzs);
           },
            splitTableDataMain() {
                let tableRowFull = false;
                let list = [];
                for (let i = 0; i < this.tableDataMain.length; i++) {
                    list.push(this.tableDataMain[i]);
                    //实收单价
                    if (this.tableDataMain[i].single_price_practical != this.tableDataMain[i].price){
                        tableRowFull = true;
                   }
                    if (list.length == 15) {
                        this.table.push(list);
                        this.tableRow.push(tableRowFull);
                        list = [];
                        tableRowFull = false;
                   }
               }
                this.table.push(list);
                this.tableRow.push(tableRowFull);
           },
            indexMethod(index) {
                return index + 1;
           }, searchWarehouseBill() {
                this.$axios({
                    method: 'post',
                    url: '/admin/transferSlipInform/search',
                    params: {
                        bill_id_kdtzs : this.formData.bill_id_kdtzs,
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.formData = response.data.warehouseBill;
                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                        this.dataSize = response.data.dataSize;
                        this.checkList = [];
                        if (this.formData.clearing_form == '欠款') {
                            this.checkList.push('欠款单')
                       } else {
                            this.checkList.push('调拨单')
                       }
                        if (this.formData.tax_tate_warehouse_bill == '' || this.formData.tax_tate_warehouse_bill == null) {
                            this.checkList.push('B');
                       } else {
                            this.checkList.push('A');
                       }
                        this.clearingFormList = [];
                        if (this.formData.clearing_form != null && this.formData.clearing_form != '') {
                            if (this.formData.clearing_form.indexOf('+') > 0) {
                                let s = this.formData.clearing_form.split('+');
                                for (let i = 0; i < s.length; i++) {
                                    this.clearingFormList.push(s[i])
                               }
                           } else {
                                this.clearingFormList.push(this.formData.clearing_form)
                           }
                       }
                        this.pageSize = this.dataSize % 15 == 0 ? this.dataSize / 15 : this.$XEUtils.toInteger((this.dataSize / 15)) + 1;
                        this.splitTableDataMain();
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
       },
        created() {
            this.formData.bill_id_kdtzs = this.$route.query.bill_id_kdtzs;
            this.searchWarehouseBill();
       }
   }


</script>

<style scoped>
    .my-list-row {
        display: inline-block;
        width: 100%;
   }

    .my-list-col {
        float: left;
        width: 33.33%;
        line-height: 28px;
   }

    .my-top, .my-bottom {
        font-size: 12px;
   }

    .my-top {
        margin-bottom: 5px;
   }

    .my-bottom {
        margin-top: 30px;
        text-align: right;
   }
</style>
